import i18n from 'i18next';
import PropTypes from 'prop-types';
import $ from 'jquery';
import React from 'react';
import { Trans } from 'react-i18next';
import { Banner, Button, Form, Input, Checkbox } from '@swingvy/design-system';
import { styled } from '@voyagers/theme';
import IntroApi from 'js/network/IntroApi';
import DataProvider from 'js/network/DataProvider';
import RoutePath from 'js/app/RoutePath';

const TermsAndConditions = styled.div`
    ${({ theme }) => theme.typos.Body[5]};
    color: ${(props) => props.theme.colors.Grey[900]};
    .swv-input-box.swv-input-checkbox-box {
        margin-bottom: 0;
        label {
            font-size: 12px;
            > a {
                margin-left: 0;
                padding: 0;
                ${({ theme }) => theme.typos.Body[5]};
            }
        }
    }
    margin: 30px 0 12px 0;
    text-align: center;
`;
class SetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.formEl = null;
        this.state = {
            password: '',
            confirmPassword: '',
            isChanging: false,
            errorMessage: '',
            checkTermsAndPrivacy: false,
        };
        this.setPasswordHandler = this.setPasswordHandler.bind(this);
    }

    setPasswordHandler() {
        this.setState({ isChanging: true, errorMessage: '' });

        const { q, status } = this.props;
        new DataProvider(IntroApi.ON_BOARDING_SET_PASSWORD)
            .setParam(
                JSON.stringify({
                    userStatus: { code: status },
                    password: this.state.password,
                }),
            )
            .addQuery('q', q)
            .setCallback((result) => {
                this.setState({ isChanging: false });
                this.props.userPasswordSetAnd(Number(result.status));
            })
            .setErrorCallback(() => {
                this.setState({ isChanging: false });
            })
            .request();
    }

    render() {
        return (
            <div>
                <div
                    className="content-title"
                    style={{ textAlign: 'center', marginBottom: '16px' }}
                >
                    {i18n.t('onboarding.set_password.title_v2')}
                </div>
                <div className="content-description" style={{ textAlign: 'center' }}>
                    {i18n.t('onboarding.set_password.description')}
                </div>
                <Banner
                    type="error"
                    className="action-error-banner"
                    header={i18n.t('onboarding.set_password.msg_unknown_error_reset_password')}
                    show={!!this.state.errorMessage}
                    showCloseIcon={false}
                />
                <Form formRef={(el) => (this.formEl = el)} submitHandler={this.setPasswordHandler}>
                    <Input
                        type="password"
                        onIdCreated={(id) => (this.passwordId = id)}
                        labelTitle={i18n.t('form.password.label')}
                        minLength={8}
                        value={this.state.password}
                        required
                        onChange={(value) => this.setState({ password: value })}
                    />
                    <Input
                        type="password"
                        labelTitle={i18n.t('form.password.label_confirm')}
                        minLength={8}
                        equalTo={`#${this.passwordId}`}
                        value={this.state.confirmPassword}
                        required
                        onChange={(value) => this.setState({ confirmPassword: value })}
                    />
                    <TermsAndConditions>
                        <Checkbox
                            checked={this.state.checkTermsAndPrivacy}
                            onChange={(value) => this.setState({ checkTermsAndPrivacy: value })}
                            required
                            attr={{ 'data-parsley-errors-messages-disabled': true }}
                            labelTitle={
                                <Trans
                                    i18nKey="register.form.agreement.text"
                                    components={[
                                        <a
                                            className="swv-btn swv-btn-links swv-theme-light"
                                            href={RoutePath.SWINGVY_LANDING.TOS}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {i18n.t('register.form.agreement.terms')}
                                        </a>,
                                        <a
                                            className="swv-btn swv-btn-links swv-theme-light"
                                            href={RoutePath.SWINGVY_LANDING.PRIVACY}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {i18n.t('register.form.agreement.privacy_policy')}
                                        </a>,
                                    ]}
                                />
                            }
                        />
                    </TermsAndConditions>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            className="btn-submit-action"
                            type="primary"
                            size="large"
                            title={i18n.t('onboarding.set_password.submit_button.title')}
                            isLoading={this.state.isChanging}
                            onClick={(env) => {
                                env.preventDefault();
                                $(this.formEl).submit();
                            }}
                        />
                    </div>
                </Form>
            </div>
        );
    }
}

SetPassword.propTypes = {
    q: PropTypes.string,
    status: PropTypes.number,
    userPasswordSetAnd: PropTypes.func,
};

export default SetPassword;
